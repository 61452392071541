import React from "react";
import Security from "containers/visuella/Security";

const SecurityPage = () => {
  return (
    <>
      <Security />
    </>
  );
};
export default SecurityPage;
